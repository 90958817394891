<template>
  <div class="search-page">
    <div class="search-page__top">
      <div class="search-page__icon-block">
        <router-link to="/"><img src="@/assets/img/gdp-blue-logo.svg"/></router-link>
      </div>
      <div class="search-page__close-button" @click="$router.go(-1)">
        <a><img class="close" alt="close-button" :src="require('@/assets/img/close-button.svg')"/></a>
      </div>
    </div>
    <div class="search-page__search-block" v-bind:class="{ searchBlockActive: isActive }">
      <div class="search-page__search-div" v-bind:class="{ searchDivActive: isActive }">
        <input
          v-model="searchQuery"
          v-on:keydown.enter="onEnter"
          type="text"
          class="search-input"
          v-bind:class="{ searchInputActive: isActive }"
          required
        />
        <span class="floating-label" v-bind:class="{ floatingLabelActive: isActive }">SEARCH</span>
      </div>
    </div>
    <div
      v-if="resultNotFound"
      :key="searchNotFoundTransition"
      class="search-page__result-not-found"
      v-bind:class="{ 'result-not-found': resultNotFound }"
    >
      RESULT NOT FOUND
    </div>
    <div class="search-page__result-container" :key="searchTransition" v-bind:class="{ 'result-found': resultFound }">
      <div
        v-for="(companyData, index) in companyArray"
        v-bind:key="companyArray[index].field_company_name"
        class="searchResultBox"
        @click="goToPortfolio(companyArray[index].field_company_name)"
      >
        <SearchResult :desc="companyArray[index].field_company_name" :title="'PORTFOLIO'" />
      </div>
      <div
        v-for="(leaderData, index) in leaderArray"
        v-bind:key="leaderArray[index].field_leader_name"
        class="searchResultBox"
        @click="goToLeader(leaderArray[index].field_id)"
      >
        <SearchResult :desc="leaderArray[index].field_leader_name" :title="'LEADER'" />
      </div>
      <div
        v-for="(articleData, index) in resultArray"
        v-bind:key="index"
        class="searchResultBox"
        @click="goToArticle(resultArray[index].field_news_id)"
      >
        <SearchResult
          :desc="articleData.field_news_heading"
          :title="articleData.field_coverage === 'True' ? 'COVERAGE' : 'ARTICLE'"
        />
      </div>
    </div>
    <div class="loader" v-if="!resultFound && !resultNotFound && isActive">
      <img class="loader--img" src="@/assets/img/search-loader.gif" alt="loader" />
    </div>
  </div>
</template>
<script>
import { SearchResult } from '@/components'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'Search',
  props: ['routeName'],
  components: {
    SearchResult
  },
  data: function() {
    return {
      isActive: '',
      searchQuery: '',
      searchTransition: 0,
      searchNotFoundTransition: 999
    }
  },
  methods: {
    ...mapActions('search', ['getSearchResult', 'getCompanyResult', 'getLeaderResult', 'clearSearchHistory']),
    ...mapActions('about', ['getLeader']),
    goToArticle(newsID) {
      this.$router.push({ path: 'article', query: { newsID: newsID } })
    },
    goToLeader(idLead) {
      this.$router.push({ name: 'leaderdetail', params: { idleads: idLead } })
    },
    goToPortfolio(fieldId) {
      this.$router.push({ name: 'portfoliodetail', params: { currentIndex: fieldId } })
    },
    onEnter: function() {
      if (this.searchQuery !== '') {
        this.searchTransition = this.searchTransition + 1
        this.searchNotFoundTransition = this.searchNotFoundTransition + 1
        this.clearSearchHistory()
        this.getCompanyResult(this.searchQuery)
        this.getLeaderResult(this.searchQuery)
        this.getSearchResult(this.searchQuery)
        this.isActive = true
      } else {
        this.isActive = false
      }
    },
    navigateTo(path) {
      this.$router.push(path, () => {})
    }
  },
  computed: {
    ...mapState('search', ['resultArray', 'companyArray', 'leaderArray']),
    resultFound() {
      const { resultArray, companyArray, leaderArray } = this
      return (
        (resultArray && resultArray.length) ||
        (companyArray && companyArray.length) ||
        (leaderArray && leaderArray.length)
      )
    },
    resultNotFound() {
      const { resultArray, companyArray, leaderArray } = this
      return (
        resultArray && !resultArray.length && companyArray && !companyArray.length && leaderArray && !leaderArray.length
      )
    }
  },
  mounted() {
    this.getLeader()
  },
  beforeDestroy() {
    this.clearSearchHistory()
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/scss/colors';
@import '@/assets/scss/fonts';
@import '@/assets/scss/media-query-mixins';
@keyframes top {
  0% {
    transform: translateY(300%);
    opacity: 0;
  }
  30% {
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes searchResulttop {
  0% {
    transform: scaleX(0.1) translateY(500%);
    opacity: 0;
  }
  30% {
    transform: scaleX(0.1) translateY(250%);
    opacity: 0;
  }
  100% {
    transform: scaleX(1) translateY(0);
    opacity: 1;
  }
}

.search-page {
  &__top {
    display: flex;
    margin: 25px 0 0 0;
    justify-content: space-between;
    align-items: center;
    @include iphone-portrait {
      margin: 30px 0 0 0;
    }
    @include ipad-10-portrait {
      margin: 25px 0 0 0 !important;
    }
    @include ipad-default-landscape {
      margin: 30px 0 0 0;
    }
  }
  &__icon-block {
    margin: 0 0 0 80px;
    @media (max-width: 420px) {
      margin: 0 0 0 15px;
    }
    @include iphone-portrait {
      margin: 0 0 0 15px;
    }
    @include ipad-10-portrait {
      margin: 0 0 0 50px;
    }
    @include ipad-default-landscape {
      margin: 0 0 0 80px !important;
    }
    @include ipad-default-portrait {
      margin: 0 0 0 50px !important;
    }
  }
  &__icon {
    width: 75px;
    height: 25px;
  }
  &__close-button {
    margin: 0 80px 0 0;
    @media (max-width: 420px) {
      margin: 0 15px 0 0px;
    }
    @include iphone-portrait {
      margin: 0 15px 0 0;
    }
    @include ipad-10-portrait {
      margin: 0 50px 0 0;
    }
    @include ipad-default-landscape {
      margin: 0 80px 0 0 !important;
    }
    @include ipad-default-portrait {
      margin: 0 50px 0 0 !important;
    }
  }
  &__search-div {
    position: relative;
    text-align: center;
    min-height: 100px;
    animation: top 1s;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 30px;
  }
  .close {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 2px $menu-close-border;
    color: $dark-blue-color;
    background-color: primary-white;
    cursor: pointer;
    font-size: 22px;
  }
  .close:hover {
    border: solid 3px $dark-blue-color;
    transition-duration: 0.4s;
    transition-delay: 0s;
  }
  &__search-block {
    transition: 0.3s linear;
    margin: 200px 0 0 0;
    text-align: center;
    @include ipad-10-portrait {
      margin: 350px 0 0 0;
    }
  }
  .search-input {
    font-size: 50px;
    border: 0;
    outline: 0;
    background: transparent;
    border-bottom: 0.5px solid $dim-gray;
    width: 50%;
    text-align: center;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-topleft: 0px;
    -moz-border-radius-topright: 0px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    @include iphone-portrait {
      width: 80%;
      font-size: 35px;
    }
    @include ipad-10-portrait {
      width: 50% !important;
      font-size: 50px !important;
    }
    @include ipad-default-portrait {
      width: 50%;
    }
  }
  .floating-label {
    position: absolute;
    pointer-events: none;
    text-align: center;
    transition: 0.3s linear;
    object-fit: contain;
    opacity: 0.5;
    font-family: GothamBook;
    font-size: 55px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 5.5px;
    text-align: center;
    color: $dim-gray;
    @include ipad-10-portrait {
      font-size: 50px !important;
    }
  }
  input:focus ~ .floating-label,
  input:not(:focus):valid ~ .floating-label {
    transition: 0.3s linear;
    object-fit: contain;
    font-family: GothamBook;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 2.4px;
    text-align: center;
    color: $dim-gray;
    padding-bottom: 70px;
    @include ipad-10-portrait {
      font-size: 24px !important;
    }
  }
  &__result-not-found {
    display: none;
    font-family: GothamBook;
    font-size: 44px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.41;
    letter-spacing: 0.7px;
    text-align: center;
    color: $gray-44;
    @include ipad-10-portrait {
      font-size: 44px !important;
      line-height: 1.41 !important;
      letter-spacing: 0.7px !important;
    }
    @include iphone-portrait {
      font-size: 24px;
      line-height: 1.67;
      letter-spacing: normal;
    }
  }
  &__result-container {
    display: none;
    margin: 70px 200px 0 200px;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;
    animation: searchResulttop 1s;
    @media (max-width: 420px) {
      margin: 0 15px 0 15px;
    }
    @include ipad-10-portrait {
      margin: 50px 50px 0 50px !important;
    }
    @include ipad-10-landscape {
      margin: 50px 80px 0 80px;
    }
    @include iphone-portrait {
      margin: 0 15px 0 15px;
    }
    @include ipad-default-portrait {
      margin: 20px 50px 0 50px;
    }
    @include ipad-default-landscape {
      margin: 50px 80px 0 80px;
    }
    @include ipad-landscape {
      margin: 50px 80px 0 80px;
    }
    .searchResultBox {
      cursor: pointer;
      width: 45%;
      @media (max-width: 420px) {
        width: 100%;
      }
      @include ipad-10-portrait {
        margin: 0 0 30px 0;
        width: 100%;
      }
      @include ipad-10-landscape {
        width: 48%;
      }
      @include iphone-portrait {
        margin: 0 0 9px 0;
        width: 100%;
      }
    }
  }
}
.searchBlockActive {
  transition: 0.5s linear !important;
  margin: 100px 0 0 0 !important;
  text-align: center;
  @include ipad-10-portrait {
    margin: 200px 0 0 0 !important;
  }
}
.searchDivActive {
  transition: 0.5s linear !important;
  position: relative;
  text-align: center;
  min-height: 100px;
}
.floatingLabelActive {
  align-items: center;
  object-fit: contain;
  font-family: GothamBook;
  font-size: 16px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38 !important;
  letter-spacing: 1.6px !important;
  text-align: center;
  color: $dim-gray;
  transition: 0.5s linear !important;
}
.searchInputActive {
  transition: 0.5s linear !important;
  font-size: 44px !important;
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 0.5px solid $dim-gray;
  width: 25% !important;
  text-align: center;
  @media (max-width: 420px) {
    width: 40% !important;
  }
  @include iphone-portrait {
    font-size: 35px !important;
    width: 60% !important;
  }
  @include ipad-default-portrait {
    width: 40% !important;
  }
}
.result-not-found {
  display: block !important;
  margin-top: 60px;

  @include iphone-portrait {
    margin-top: 30px;
  }
}
.result-found {
  display: flex !important;
}
.loader {
  text-align: center;
  margin-top: 5%;
  &--img {
    width: 100px;
  }
}
</style>
